import React, { useContext } from 'react'
import dayjs from 'dayjs'
import { useFeature } from '@growthbook/growthbook-react'

import { BookingContext } from 'context/BookingContext'

import { Button } from 'components/Button/Button'
import WithBarLoader from 'components/Loader/WithBarLoader'
import TotalPrice from 'components/BookingQuote/TotalPrice'
import AvgPricePerNight from 'components/BookingQuote/AvgPricePerNight'

import styles from './ViewDetailsBar.module.scss'

type ViewDetailsBarProps = {
  avgPrice: number
  show: boolean
  bookNowButtonText: string
  isButtonDisabled?: boolean
  onViewDetailsClick: (isOpen: boolean) => void
  onClickBookBtn: () => void
}

const ViewDetailsBar = ({
  avgPrice,
  show,
  bookNowButtonText,
  isButtonDisabled,
  onViewDetailsClick,
  onClickBookBtn,
}: ViewDetailsBarProps) => {
  const { on: showMedianPrice } = useFeature('avg-price-v2')

  const { quote, dates, guests } = useContext(BookingContext)
  const totalPrice = quote?.price?.total ?? 0

  const hasAdults = guests?.adults ?? 0

  const now = dayjs()
  const startDate = dayjs(dates?.start)
  const endDate = dayjs(dates?.end)

  const hasValidDates = startDate.isAfter(now) && endDate.isAfter(now)

  return show ? (
    <div className={styles.main}>
      <div className={styles.price__box}>
        {totalPrice ? (
          <TotalPrice className={styles.total__price} price={totalPrice} />
        ) : (
          <WithBarLoader
            height={20}
            isLoading={!avgPrice}
            useTransparent
            width={'100%'}
          >
            {(hasValidDates && hasAdults) ||
            ((!hasValidDates || !hasAdults) && showMedianPrice) ? (
              <AvgPricePerNight
                className={styles.avg__price}
                price={avgPrice}
              />
            ) : (
              <>
                {!hasValidDates && (
                  <span className={styles.no_dates}>Add dates for prices</span>
                )}
                {hasValidDates && !hasAdults && (
                  <span className={styles.no_dates}>Add guests for prices</span>
                )}
              </>
            )}
          </WithBarLoader>
        )}
        <button
          className={styles.viewdetails__btn}
          onClick={() => onViewDetailsClick(true)}
        >
          View Details
        </button>
      </div>
      <Button
        className={styles.booknow__btn}
        disabled={isButtonDisabled}
        id="book_now_btn"
        onClick={onClickBookBtn}
        text={bookNowButtonText}
      />
    </div>
  ) : null
}

export default ViewDetailsBar
